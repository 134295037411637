import React, { useState, useRef, useEffect } from "react";
import classnames from "classnames";
import ReactGA from "react-ga";
import TextInput from "../forms/TextInput";
import Button from "../Button";
import "../../styles/RateYourWorkout.css";
import useAuth from "../../hooks/useAuth";
import postSentiment from "../../api/postSentiment";
import ControlledTooltip from "../tooltips/ControlledTooltip";

export type Rate = "angry" | "unhappy" | "neutral" | "pleased" | "delighted";

interface RateData {
  emoji: string;
  rating: Rate;
}

const rateData: RateData[] = [
  {
    rating: "angry",
    emoji: "😠"
  },
  {
    rating: "unhappy",
    emoji: "😔"
  },
  {
    rating: "neutral",
    emoji: "😐"
  },
  {
    rating: "pleased",
    emoji: "😊"
  },
  {
    rating: "delighted",
    emoji: "😍"
  }
];

interface Props {
  messages: Record<Rate, [string, string]>;
  title: string;
  id: string;
  name: string;
}

function EmojiRating(props: Props) {
  const { messages, title, id, name } = props;
  const [selectedRating, selectRating] = useState<Rate | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [message, setMessage] = useState("");
  const [hasSentMessage, setMessageSentState] = useState(false);
  const { getUserId, getAccessToken, getAuthenticationState } = useAuth();
  const [isTooltipVisible, setTooltipVisibilityState] = useState(false);
  const isLoggedIn = getAuthenticationState();

  function displayTooltip() {
    setTooltipVisibilityState(true);

    timeoutRef.current = global.setTimeout(() => {
      setTooltipVisibilityState(false);
    }, 3000);
  }

  useEffect(() => {
    return function () {
      if (timeoutRef.current) {
        global.clearTimeout(timeoutRef.current);
      }
    };
  }, [timeoutRef]);

  return (
    <div className="RateYourWorkout">
      <h3>{title}</h3>
      <div className="RateYourWorkout__emoji-container">
        {rateData.map(({ rating, emoji }) => {
          return (
            <span
              key={rating}
              className={classnames("RateYourWorkout__emoji", {
                "RateYourWorkout__emoji--selected": selectedRating === rating
              })}
              onClick={() => {
                ReactGA.event({
                  action: `Rated ${name}`,
                  category: `${name}`,
                  label: `Rated ${name} ${rating}`
                });
                return selectRating(rating);
              }}
              role="img"
              aria-label={rating}
            >
              {emoji}
            </span>
          );
        })}
      </div>
      {selectedRating && (
        <div className="RateYourWorkout__form">
          <p>{messages[selectedRating][0]}</p>
          {isLoggedIn && (
            <div className="RateYourWorkout__input-container">
              <TextInput
                styles={{ width: "100%" }}
                containerStyles={{
                  width: "100%",
                  marginBottom: 0,
                  marginRight: "8px"
                }}
                id={id}
                label={messages[selectedRating][1]}
                name={id}
                value={message}
                onChange={e => setMessage(e.target.value)}
              />
              <ControlledTooltip
                isVisible={isTooltipVisible}
                content="Thanks for sharing your thoughts"
              >
                <Button
                  size="small"
                  disabled={hasSentMessage}
                  handleClick={() => {
                    setMessageSentState(true);
                    displayTooltip();
                    return postSentiment(
                      { userId: getUserId(), message, rating: selectedRating },
                      getAccessToken()
                    );
                  }}
                >
                  Send
                </Button>
              </ControlledTooltip>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default EmojiRating;
