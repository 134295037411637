import React from "react";
import styles from "../../styles/HomeHeader.module.css";
import { OutboundLink } from "react-ga";

function HomeHeader() {
  return (
    <header className={styles.HomeHeader__container}>
      <div className={styles.HomeHeader__content}>
        <h1>Your calisthenics journey, all in one place</h1>
        <h2>
          Try our brand new Android app:{" "}
          <OutboundLink
            eventLabel="Link to Playstore"
            to="https://play.google.com/store/apps/details?id=com.caliskillsapp&gl=GB"
          >
            Handstand Journey
          </OutboundLink>
        </h2>
      </div>
    </header>
  );
}

export default HomeHeader;
