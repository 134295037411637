import axios from "axios";

interface Args {
  userId: string;
  accessToken: string;
  lastNDays: number;
}

async function getUserWorkouts(key: string, args: Args) {
  const { userId, accessToken, lastNDays } = args;

  const { data } = await axios.get(
    `${process.env.GATSBY_SERVER_URL}/workouts/${userId}?lastNDays=${lastNDays}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    }
  );

  return data;
}

export default getUserWorkouts;
