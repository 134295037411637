import React from "react";
import ProgressChart from "../charts/ProgressChart/ProgressChart";
import ProgressionsRadarChart from "../charts/ProgressionsRadarChart/ProgressionsRadarChart";
import exercises from "../../data/exercises/exercises";
import useSkillsQuery from "../../hooks/useSkillsQuery";
import { Dictionary } from "../../models";
import StatsCard from "../StatsCard";
import useGetUserSubscription from "../../hooks/useGetUserSubscription";

const exerciseCount = Object.keys(exercises).length;

function getCompletedSkillsCount(skills: Dictionary<boolean>) {
  return Object.values(skills).reduce((prev, curr) => {
    if (curr) {
      return prev + 1;
    }

    return prev;
  }, 0);
}

function ProgressSegment() {
  const { data } = useSkillsQuery();
  const { data: subcriptionData } = useGetUserSubscription();

  const isPremiumUser = subcriptionData?.subscription?.active;

  const completedSkillsCount = data?.skills
    ? getCompletedSkillsCount(data.skills)
    : 0;

  return (
    <div
      style={{
        margin: "0 auto",
        padding: "32px 16px",
        textAlign: "left"
      }}
    >
      <h2
        style={{ maxWidth: "500px", marginLeft: "auto", marginRight: "auto" }}
      >
        Progress
      </h2>
      <StatsCard
        skillCount={exerciseCount}
        selectedSkillCount={completedSkillsCount}
      />
      <div style={{ maxWidth: "600px", margin: "0 auto" }}>
        <ProgressChart isPremium={Boolean(isPremiumUser)} />
      </div>
      <div style={{ maxWidth: "600px", margin: "0 auto" }}>
        <ProgressionsRadarChart isPremium={Boolean(isPremiumUser)} />
      </div>
    </div>
  );
}

export default ProgressSegment;
