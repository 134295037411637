import React from "react";
import PageContent from "./PageContent";
import HSeparator from "../HSeparator";
import Footer from "../Footer";
import NavRoutes from "../NavRoutes";

interface Props {
  children: React.ReactNode;
}

function HomePageLayout({ children }: Props) {
  return (
    <>
      <NavRoutes />
      <HSeparator />
      <PageContent hasSideSheet={false}>{children}</PageContent>
      <Footer hasSideSheet={false} />
    </>
  );
}

export default HomePageLayout;
