import React from "react";
import { PlancheIcon } from "../../exerciseIcons";
import styles from "../../styles/Segment.module.css";
import { navigate } from "gatsby";

function ExercisesSegment() {
  return (
    <div style={{ maxWidth: "500px", margin: "0 auto", padding: "32px 16px" }}>
      <h2 style={{ textAlign: "left" }}>Exercises</h2>
      <div
        onClick={() => navigate("/exercises/")}
        tabIndex={0}
        data-testid="exercises-container"
        className={`${styles.Segment} ${styles.ClickableSegment}`}
      >
        <img
          style={{ height: "90px" }}
          alt="Person performing exercise."
          src={PlancheIcon}
        />
        <h3>Exercise Library</h3>
      </div>
    </div>
  );
}

export default ExercisesSegment;
