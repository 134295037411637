import React, { useState, useRef, useEffect } from "react";
import { PlankIcon } from "../../exerciseIcons";
import styles from "../../styles/Segment.module.css";
import { navigate, Link } from "gatsby";
import useAuth from "../../hooks/useAuth";
import ControlledTooltip from "../tooltips/ControlledTooltip";
import useWorkoutsQuery from "../../hooks/useWorkoutsQuery";

interface Args {
  isLoggedIn: boolean;
  workoutCount: number;
  isLoading: boolean;
}

function getWorkoutMessage(args: Args) {
  if (args.isLoading) return "";

  if (!args.isLoggedIn) {
    return (
      <p>
        You'll need to <Link to="/login/">sign up</Link> to workout using Cali
        Skills.
      </p>
    );
  }

  if (args.workoutCount === 0) {
    return (
      <p>
        You haven't worked out yet this week.{" "}
        <Link to="/workouts/recommended-routine">Let's change that</Link>
      </p>
    );
  }

  return (
    <p>You've completed {args.workoutCount} workouts over the last week.</p>
  );
}

function WorkoutsSegment() {
  const [isTooltipVisible, setTooltipVisibilityState] = useState(false);
  const { data, status } = useWorkoutsQuery({ lastNDays: 7 });
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { getAuthenticationState } = useAuth();
  const workoutCount = data?.length ?? 0;

  const isLoggedIn = getAuthenticationState();

  const workoutMessage = getWorkoutMessage({
    workoutCount,
    isLoading: status === "loading",
    isLoggedIn
  });

  function displayTooltip() {
    timeoutRef.current = global.setTimeout(() => {
      setTooltipVisibilityState(false);
    }, 2500);

    setTooltipVisibilityState(true);
  }

  useEffect(() => {
    return function () {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  });

  return (
    <div
      style={{
        maxWidth: "500px",
        margin: "0 auto",
        padding: "32px 16px",
        textAlign: "left"
      }}
    >
      <h2>Workouts</h2>
      {workoutMessage}
      {/* 
      this can come later
      <p>M T W T F S S</p>
       */}
      <ControlledTooltip
        isVisible={isTooltipVisible}
        interactive
        content={
          <p style={{ margin: 0 }}>
            Want to workout? <Link to="/login">Sign up</Link> to Cali Skills and
            workout for free.
          </p>
        }
      >
        <div
          onClick={() => {
            if (isLoggedIn) {
              return navigate("/workouts/recommended-routine/");
            }

            return displayTooltip();
          }}
          data-testid="recommended-routine-container"
          tabIndex={0}
          className={`${styles.Segment} ${styles.ClickableSegment}`}
        >
          <img
            style={{ height: "90px" }}
            alt="Person performing exercise."
            src={PlankIcon}
          />
          <h3>Perform the Recommended Routine</h3>
        </div>
      </ControlledTooltip>
      {isLoggedIn ? (
        <p>
          Or you can{" "}
          <Link to="/workouts/workouts-list/">try our other routines</Link> or{" "}
          <Link to="/workouts/">build your own workout</Link>.
        </p>
      ) : (
        <p>
          With Cali Skills, you can perform bespoke workouts built around your
          progress.
        </p>
      )}
    </div>
  );
}

export default WorkoutsSegment;
