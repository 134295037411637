import React from "react";
import EmojiRating, { Rate } from "./base/EmojiRating";

export const messages: Record<Rate, [string, string]> = {
  angry: [
    "We're sorry to hear that you find the new layout frustrating",
    "How can we improve the improve the layout?"
  ],
  unhappy: [
    "We're sorry to hear that you don't like the new layout",
    "How can we improve your next workout?"
  ],
  neutral: [
    "We want the new layout to bring a smile to your face",
    "What can we do to make this happen?"
  ],
  pleased: [
    "We're pleased that you like our new layout",
    "What changes do you enjoy the most?"
  ],
  delighted: [
    "We're pleased that you love our new layout",
    "What changes do you enjoy the most?"
  ]
};

function RateOurHomePage() {
  return (
    <EmojiRating
      title="What do you think of the Cali Skills redesign?"
      messages={messages}
      name="Home"
      id="home-feedback"
    />
  );
}

export default RateOurHomePage;
