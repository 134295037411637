import React, { useContext } from "react";
import "../styles/Header.css";
import SheetContext from "../context/SheetContext";

const DEFAULT_LABEL_TEXT = "Using Cali Skills";

interface Props {
  title: string;
  hasSideSheet?: boolean;
  sideSheetLabelText?: string;
}

function Header({
  title,
  hasSideSheet,
  sideSheetLabelText = DEFAULT_LABEL_TEXT
}: Props) {
  const { setSheetVisibility } = useContext(SheetContext);

  return (
    <div className="Header">
      <h1 data-testid={`header-${title}`}>{title}</h1>
      {hasSideSheet && (
        <button onClick={() => setSheetVisibility(true)}>
          <p>{sideSheetLabelText}</p>
        </button>
      )}
    </div>
  );
}

export default Header;
