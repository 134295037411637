import React, { useContext, useEffect } from "react";
import classnames from "classnames";
import Header from "../Header";
import SheetContext from "../../context/SheetContext";
import "../../styles/PageContent.css";
import { useWindowSize } from "react-use";

interface Props {
  hasSideSheet: boolean;
  pageTitle?: string;
  children: React.ReactNode;
}

function PageContent(props: Props) {
  const { hasSideSheet, pageTitle, children } = props;
  const { isSheetVisible } = useContext(SheetContext);
  const { width } = useWindowSize();

  useEffect(() => {
    if (isSheetVisible && width < 900) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isSheetVisible, width]);

  return (
    <main
    role="main"
      id="main-content"
      className={classnames("PageContent", {
        "PageContent--with-sidesheet": hasSideSheet
      })}
    >
      <div
        className={classnames("PageContent__overlay", {
          "PageContent__overlay--visible": isSheetVisible
        })}
      />
      {pageTitle && <Header hasSideSheet={hasSideSheet} title={pageTitle} />}
      {children}
    </main>
  );
}

export default PageContent;
