import React from "react";
import classnames from "classnames";
import { RingsIcon } from "../icons";
import Icon from "./Icon";
import "../styles/Footer.css";
import { Link } from "gatsby";
import { OutboundLink } from "react-ga";

interface Props {
  hasSideSheet: boolean;
  hasBorder?: boolean;
}

function Footer(props: Props) {
  const { hasSideSheet, hasBorder = true } = props;

  return (
    <>
      {hasBorder && <hr />}
      <footer
        className={classnames("Footer", {
          "Footer--narrow": hasSideSheet
        })}
      >
        <div className="Footer__heading">
          <Icon
            src={RingsIcon}
            title="Example of an locked skill node"
            containerWidth={40}
          />
          <h3 className="Footer__title">Cali Skills</h3>
        </div>
        <ul className="Footer__list">
          <li>
            <Link to="/skill-tree/">Skill Tree</Link>
          </li>
          <li>
            <Link to="/workouts/">Workouts</Link>
          </li>
          <li>
            <Link to="/exercises/">Exercise List</Link>
          </li>
          <li>
            <Link to="/equipment/">Equipment</Link>
          </li>
        </ul>
        <ul className="Footer__list">
          <li>
            <Link to="/about/">About Us</Link>
          </li>
          <li>
            <Link to="/release-notes/">Release Notes</Link>
          </li>
          <li>
            <Link to="/privacy-policy/">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/terms-and-conditions/">Terms and Conditions</Link>
          </li>
          <li>
            <Link to="/pricing/">Pricing</Link>
          </li>
        </ul>
        <ul className="Footer__list">
          <li>
            <Link to="/contact-us/">Contact Us</Link>
          </li>
          <li>
            <OutboundLink
              target="_blank"
              to="https://shop.calisthenicsskills.com/"
              eventLabel="Footer outbound link to Shop"
            >
              Shop
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              target="_blank"
              to="https://www.instagram.com/caliskills.app/"
              eventLabel="Footer outbound link to Instagram"
            >
              Instagram
            </OutboundLink>
          </li>
        </ul>
      </footer>
    </>
  );
}

export default Footer;
