import axios from "axios";
import { Rate } from "../components/base/EmojiRating";

interface Body {
  userId: string;
  rating: Rate;
  message: string;
}

function postSentiment(body: Body, accessToken: string) {
  axios.post(
    `${process.env.GATSBY_SERVER_URL}/workouts/sentiment`,
    JSON.stringify(body),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
}

export default postSentiment;
