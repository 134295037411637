import React from "react";
import SEO from "../components/SEO";
import HomeHeader from "../components/home/HomeHeader";
import ErrorBoundary from "../components/errorBoundaries/ErrorBoundary";
import ReactGA from "react-ga";
import SkillsSegment from "../components/home/SkillsSegment";
import ExercisesSegment from "../components/home/ExercisesSegment";
import ProgressSegment from "../components/home/ProgressSegment";
import WorkoutsSegment from "../components/home/WorkoutsSegment";
import HomePageLayout from "../components/layouts/HomePageLayout";
import styles from "../styles/HomePage.module.css";
import RateOurHomePage from "../components/RateOurHomePage";
import AboutSegment from "../components/home/AboutSegment";

ReactGA.event({
  category: "Application",
  action: "Load",
  label: "Skill tree has fully loaded",
  nonInteraction: true
});

function SkillTreePage() {
  return (
    <HomePageLayout>
      <SEO
        path="/home/"
        description="Everything you need for your calisthenics journey, including your skills, progress, exercises, and more."
      />
      <HomeHeader />
      <ErrorBoundary>
        <div className={styles.HomePage}>
          <div className={styles.HomePage__child}>
            <SkillsSegment />
            <WorkoutsSegment />
          </div>
          <div className={styles.HomePage__child}>
            <ProgressSegment />
          </div>
          <div className={styles.HomePage__child}>
            <AboutSegment />
            <ExercisesSegment />
          </div>
        </div>
        <RateOurHomePage />
      </ErrorBoundary>
    </HomePageLayout>
  );
}

export default SkillTreePage;
