import React from "react";
import classnames from "classnames";
import '../styles/HSeparator.css';

interface Props {
  dotted?: boolean;
}

function HSeparator(props: Props) {
  const { dotted } = props;

  return (
    <div
      className={classnames("HSeparator", {
        "HSeparator--dotted": dotted
      })}
      data-testid="h-separator"
    />
  );
}

export default HSeparator;
