import React from "react";
import { Link } from "@reach/router";
import DonateButton from "../DonateButton";
import useIsSubscriptionActive from "../../hooks/useIsSubscriptionActive";

function AboutSegment() {
  const isSubscriptionActive = useIsSubscriptionActive();

  if (isSubscriptionActive) return null;

  return (
    <div style={{ maxWidth: "500px", margin: "0 auto", padding: "32px 16px" }}>
      <h2 style={{ textAlign: "left" }}>Thanks for using Cali Skills!</h2>
      <p>
        Hey Cali crew, I’m Andrico, the creator of Cali Skills. I just want to
        say thanks for using Cali Skills, and I hope it helps you reach your
        fitness goals.
      </p>
      <p>
        Cali Skills offers a very generous free tier, and I’d love to keep it
        that way. Cali Skills requires time and money to keep running, and if
        you’d like to show your support, you can donate or{" "}
        <Link to="/pricing">upgrade</Link>. All the best with your journey.
      </p>
      <DonateButton location="home page" />
    </div>
  );
}

export default AboutSegment;
