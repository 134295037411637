import React from "react";
import ReactGA from "react-ga";

interface Props {
  location: string;
}

function DonateButton(props: Props) {
  return (
    <form
      action="https://www.paypal.com/cgi-bin/webscr"
      method="post"
      target="_top"
    >
      <input type="hidden" name="cmd" value="_s-xclick" />
      <input type="hidden" name="hosted_button_id" value="9JWFGJHCH3744" />
      <input
        onClick={() => {
          ReactGA.event({
            action: "Clicked the Donate button",
            category: "Outbound",
            label: `Clicked the Donate button displayed - ${props.location}`
          });
        }}
        type="image"
        src="https://www.paypalobjects.com/en_GB/i/btn/btn_donate_SM.gif"
        name="submit"
        style={{ border: 0 }}
        title="PayPal - The safer, easier way to pay online!"
        alt="Donate with PayPal button"
      />
    </form>
  );
}

export default DonateButton;
