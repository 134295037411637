import React, { useState } from "react";
import SkillTreeContainer from "../SkillTreeContainer";
import skillTreeSchemas, { TreeSchema } from "../../data/skills";
import { SkillProvider, SkillTreeGroup } from "../../bst_fork";
import { caliSkillsTreeTheme, COLOURS } from "../../constants";
import styles from "../../styles/SkillsSegment.module.css";
import { Link } from "gatsby";
import useFocusQuery from "../../hooks/useFocusQuery";
import Loader from "../Loader";
import useSkillsQuery from "../../hooks/useSkillsQuery";
import { Dictionary } from "../../models";
import { useEffectOnce } from "react-use";

function SkillsSegment() {
  const { data: focusData, status } = useFocusQuery();
  const { data: skillsData, status: skillsStatus } = useSkillsQuery();

  const defaultSkillTree = skillTreeSchemas[0];
  const focusedSkillTree = skillTreeSchemas.find(
    ({ treeId }) => treeId === focusData?.treeId
  );

  const skillTree = focusedSkillTree ? focusedSkillTree : defaultSkillTree;
  const focusCopy = focusedSkillTree
    ? `You're training for the ${skillTree.title}.`
    : `You aren't focusing on a skill yet, so we'll start you on the ${skillTree.title}.`;

  return (
    <div
      style={{
        margin: "0 auto",
        padding: "32px 16px",
        textAlign: "left",
        maxWidth: "500px"
      }}
    >
      <h2>Skills</h2>
      <p>
        {focusCopy} You can change your focused skill by clicking a skill's{" "}
        <span style={{ color: COLOURS.ORANGE }}>☆</span> on the{" "}
        <Link to="/skill-tree/">skills page</Link>.
      </p>
      <div
        style={{ maxWidth: "400px", margin: "0 auto" }}
        className={styles.SkillsSegment__SkillTreeWrapper}
      >
        <SkillTree
          isLoading={status === "loading" || skillsStatus === "loading"}
          skillTree={skillTree}
          skills={skillsData?.skills ?? {}}
        />
      </div>
      <div style={{ padding: "8px 0" }}>
        <p>
          Not sure when to mark a skill as achieved? Aim for 6-8 clean reps, or
          a 15-20 second hold.
        </p>
      </div>
    </div>
  );
}

interface SkillTreeProps {
  isLoading: boolean;
  skillTree: TreeSchema;
  skills: Dictionary<boolean>;
}

function SkillTree(props: SkillTreeProps) {
  const { isLoading, skillTree, skills } = props;
  const [componentHasMounted, setComponentIsMountedState] = useState(false);

  useEffectOnce(() => {
    setComponentIsMountedState(true);
  });

  if (isLoading || !componentHasMounted) return <Loader />;

  return (
    <SkillProvider>
      <SkillTreeGroup theme={caliSkillsTreeTheme}>
        {() => {
          return (
            <span
              style={{ width: "100%" }}
              className={styles.SkillsSegment__SkillTreeWrapper}
            >
              <SkillTreeContainer
                key={skillTree.treeId}
                displayFocusButton={false}
                closedByDefault
                skillTreeSchema={skillTree}
                savedData={skills}
              />
            </span>
          );
        }}
      </SkillTreeGroup>
    </SkillProvider>
  );
}

export default SkillsSegment;
