import { useQuery } from "react-query";
import getUserWorkouts from "../api/getUserWorkouts";
import useAuth from "./useAuth";
import { CreatedWorkout } from "../models";

type TKey = "workouts";

interface TVariables {
  userId: string;
  accessToken: string;
  lastNDays: number;
}

interface Args {
  lastNDays: number;
}

function useWorkoutsQuery({ lastNDays }: Args) {
  const {
    getUserId,
    getAccessToken,
    getAuthenticationState,
    tokenRenewalComplete
  } = useAuth();

  const shouldFetch = getAuthenticationState() && tokenRenewalComplete;

  const userId = shouldFetch ? getUserId() : "";
  const accessToken = shouldFetch ? getAccessToken() : "";

  const { status, data, error } = useQuery<
    CreatedWorkout[],
    [TKey, TVariables]
  >(
    [
      "workouts",
      {
        userId,
        accessToken,
        lastNDays
      }
    ],
    getUserWorkouts,
    { enabled: shouldFetch }
  );

  return {
    status,
    data,
    error
  };
}

export default useWorkoutsQuery;
